<template>
  <a-modal
    title="修改企业配置"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('configok', model) }"
    @cancel="() => { $emit('configcancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="configform" labelAlign="left">
        <a-row :gutter="[16,16]">
          <a-col :span="4">
            <a-form-item label="是否开启行政车辆功能">
              <a-switch v-decorator="['allow_car', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="是否开启非申报功能">
              <a-switch v-decorator="['allow_gateway_application', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="是否开启基建类">
              <a-switch v-decorator="['allow_infrastructure', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="是否开启渣土">
              <a-switch v-decorator="['allow_muck', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="是否开启电商模块">
              <a-switch v-decorator="['open_e_commerce', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-form-item label="corporation_id" v-show="false">
            <a-input v-decorator="['corporation_id', {rules: [{required: false, message: '请输入'}]}]" />
          </a-form-item>
          <a-col :span="4">
            <a-form-item label="是否开启公共仓">
              <a-switch v-decorator="['is_supervised_warehouse', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <div style="display: inline-block">当前仓库：{{ supervised_warehouse }}</div>
            <a-form-item
              label="选择公共仓"
              :label-col="formLayout.labelCol"
              :wrapper-col="formLayout.wrapperCol">
              <a-select
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'supervised_warehouse_id'
                ]"
                placeholder="选择公共仓"
                :default-active-first-option="false"
                @search="handlehouseSearch"
                @change="handlehouseChange"
              >
                <a-select-option v-for="d in dataSource_house" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="[16,16]">
          <a-col :span="4">
            <a-form-item label="是否开启浪潮QID">
              <a-switch v-decorator="['use_inspur_qid', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="默认快递公司">
              <a-select
                v-decorator="['default_logistics']"
                @change="handleLogisticsChange">
                <a-select-option
                  v-for=" (val, key) in this.$Dictionaries.logistics_company"
                  :key="key"
                  :value="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { corporation_config_list } from '@/api/corporation'
import { warehouse_list } from '@/api/c_wms_warehouse'
// 表单字段
const fields = ['corporation_id', 'last_update', 'default_logistics', 'allow_car', 'allow_gateway_application', 'allow_infrastructure', 'allow_muck', 'is_supervised_warehouse', 'use_inspur_qid', 'open_e_commerce']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 24 }
      }
    }
    return {
      supervised_warehouse: '',
      dataSource_house: [],
      configform: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    fields.forEach(v => this.configform.getFieldDecorator(v))
    this.getinfo()
  },
  methods: {
    handleLogisticsChange (value) {
      console.log(value)
    },
    handlehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_house = !value ? [] : result
      })
    },
    handlehouseChange (value) {
      console.log(value)
      const obj = this.dataSource_house.find((item) => {
        return item.id === value
      })
      console.log(obj)
      this.supervised_warehouse = obj.name
    },
    getinfo () {
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        console.log('custom modal created', this.model, this.$form)
        this.get(this.model.id)
      })
    },
    get (id) {
      corporation_config_list(id).then(({ data }) => {
        console.log(data, 2323232)
        this.supervised_warehouse = data.supervised_warehouse
        const { configform } = this
        data.default_logistics = data.default_logistics + ''
        const formData = pick(data, fields)
        setTimeout(() => {
          configform.setFieldsValue(formData)
        })
      })
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
