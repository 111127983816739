<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="企业名称">
              <a-input v-model="queryParam.name" placeholder="企业名称"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="所在园区">
              <a-select v-model="queryParam.location" placeholder="请选择" default-value="0">
                <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="标签">
              <a-select mode="tags" placeholder="请选择" @change="handleStatusChange">
                <a-select-option v-for=" (item, index) in allTags" :value="item.id + ''" :key="index"> {{ item.name }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option v-for="item in dictionaries.status" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="advanced">
            <a-col :md="3" :sm="24">
              <a-form-item label="规模以上企业">
                <a-switch v-model="queryParam.is_above_size"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="法人">
                <a-input v-model="queryParam.legal_person" placeholder="法人"/>
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="!advanced && 4 || 2" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { overflow: 'hidden' } || {} ">
              <!--              <a @click="toggleAdvanced" style="margin-left: 8px;  display: block; margin-bottom: 15px">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'"/>
              </a>-->
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <!--              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>-->
            </span></a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div
        slot="expandedRowRender"
        slot-scope="record"
        style="margin: 0">
        <a-row
          :gutter="24"
          :style="{ marginBottom: '12px' }">
          <a-col :span="8">
            创建时间：{{ record.create_time }}
          </a-col>
          <a-col :span="8">
            注册地址：{{ record.domicile }}
          </a-col>
          <a-col :span="8">
            统一社会信用代码：{{ record.credit_code }}
          </a-col>
          <a-col :span="24">
            备注：{{ record.remark }}
          </a-col>
        </a-row>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:edit>编辑</a>
          <a-divider type="vertical" />
        </template>
        <a-dropdown>
          <a class="ant-dropdown-link">
            更多 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-action:delete>
              <a-popconfirm
                title="确定要删除么？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelet(record)"
              >
                <a>删除</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a @click="showDrawer(record)">标签设置</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="handleConfig(record)">企业配置</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="handleMaxPermissionConfig(record)">最大权限</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <config-form
      v-if="configVisible"
      ref="configModal"
      :visible="configVisible"
      :loading="configLoading"
      :model="configmdl"
      @configcancel="handleConfigCancel"
      @configok="handleConfigOk"
    />
    <max-role-create
      v-if="maxPermissionVisible"
      ref="maxRoleModal"
      :visible="maxPermissionVisible"
      :loading="maxPermissionLoading"
      :model="maxPermissionConfigmdl"
      @cancel="handleMaxPermissionConfigCancel"
      @ok="handleMaxPermissionConfigOk"
    />
    <a-drawer
      title="标签设置"
      placement="right"
      :closable="false"
      :visible="DrawerVisible"
      @close="onCloseDrawer"
      :after-visible-change="afterVisibleChange"
    >
      <p :style="{ marginRight: '8px' }">请选择企业标签:</p>
      <template v-for="tag in tags">
        <a-checkable-tag
          :key="tag.id"
          :checked="selectedTags.indexOf(tag.name) > -1"
          @change="checked => handleTagChange(tag, checked)"
        >
          {{ tag.name }}
        </a-checkable-tag>
      </template>
    </a-drawer>
  </div>
</template>

<script>
import { STable } from '@/components'
import { corporation_list, corporation_create, corporation_delete, corporation_tag_create, corporation_tag_delete, corporation_config_partial_update } from '@/api/corporation'
import { tag_list } from '@/api/tag'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import ConfigForm from '../modules/ConfigForm'
import { Drawer, Tag } from 'ant-design-vue'
import MaxRoleCreate from '@/views/a-corporation/table/MaxRoleCreate'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    Drawer,
    Tag,
    ConfigForm,
    MaxRoleCreate
  },
  data () {
    return {
      configHouseLoading: false,
      configHouseVisible: false,
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      configVisible: false,
      configLoading: false,
      maxPermissionVisible: false,
      maxPermissionLoading: false,
      dictionaries: Dictionaries,
      DrawerVisible: false,
      current_corporation_id: null,
      allTags: [],
      // 创建窗口控制
      visible: false,
      tags: [],
      selectedTags: [],
      mdl: {},
      configmdl: {},
      maxPermissionConfigmdl: {},
      // 高级搜索 展开/关闭
      advanced: true,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '企业名称',
          dataIndex: 'name',
          ellipsis: true
        },
       /* {
          title: '创建日期',
          dataIndex: 'create_time',
          width: 0
        },
        {
          title: '注册地址',
          dataIndex: 'domicile',
          sorter: true
        }, */
        {
          title: '注册资本',
          dataIndex: 'registered_capital',
          ellipsis: true
        },
        {
          title: '企业状态',
          dataIndex: 'status',
          customRender: (text) => this.dictionaries.status.find((item) => !!item[text])[text]
        },
        {
          title: '园区',
          dataIndex: 'location',
          customRender: (text) => this.dictionaries.location.find((item) => !!item[text])[text]
        },
        {
          title: '进出口企业代码',
          dataIndex: 'import_export_code',
          customRender: (text) => text || '--',
          ellipsis: true
        },
        {
          title: '法人',
          dataIndex: 'legal_person'
        },
        {
          title: '法人手机',
          dataIndex: 'legal_person_cellphone',
          customRender: (text) => text || '--'
        },
        {
          title: '规模以上企业',
          dataIndex: 'is_above_size',
          customRender: (text) => text && '是' || '否'
        },
       /* {
          title: '统一社会信用代码',
          dataIndex: 'credit_code',
          sorter: true
        }, */
      /*  {
          title: '备注',
          dataIndex: 'remark',
          sorter: true
        }, */
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return corporation_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.getTags()
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    handleStatusChange (value) {
      console.log(`selected ${value}`)
      this.queryParam.tag_ids = value.join(',')
    },
    getTags () {
      tag_list().then((res) => {
        console.log(res.data, '我是quanbu标签')
        this.allTags = res.data || []
      })
      },
    handleTagChange (tag, checked) {
      console.log()
       const { selectedTags } = this
      const nextSelectedTags = checked
        ? [...selectedTags, tag.name]
        : selectedTags.filter(t => t !== tag.name)
      console.log('你选的标签: ', nextSelectedTags)
      if (checked) {
        corporation_tag_create({ corporation_id: this.current_corporation_id, tag_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      } else {
        corporation_tag_delete({ corporation_id: this.current_corporation_id, tag_id: tag.id }).then(() => { this.selectedTags = nextSelectedTags })
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleConfigCancel () {
      this.configVisible = false
      const form = this.$refs.configModal.configform
      form.resetFields() // 清理表单数据（可不做）
    },
    handleConfigOk (record) {
      const form = this.$refs.configModal.configform
      this.configLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          corporation_config_partial_update(values, record.id).then(res => {
            this.configVisible = false
            this.configLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            console.log('删除成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.configLoading = false
        }
      })
    },
    handleMaxPermissionConfigCancel () {
      this.maxPermissionVisible = false
    },
    handleMaxPermissionConfigOk (record) {
      const form = this.$refs.maxRoleModal.configform
      this.configLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          corporation_config_partial_update(values, record.id).then(res => {
            this.configVisible = false
            this.configLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            console.log('删除成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.configLoading = false
        }
      })
    },
    handleEdit (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/corporation/CorporationInformation/' + record.id })
    },
    handleDelet (record) {
      corporation_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleConfig (record) {
      this.configmdl = record
      this.configVisible = true
      this.configLoading = false
    },
    handleMaxPermissionConfig (record) {
      this.maxPermissionConfigmdl = record
      console.log(this.maxPermissionConfigmdl)
      this.maxPermissionVisible = true
      this.maxPermissionLoading = false
    },
    //  显示标签抽屉
    showDrawer (record) {
      this.DrawerVisible = true
      tag_list().then((res) => {
        console.log(res.data, '我是quanbu标签')
        const allTags = res.data || []
        corporation_list({ id: record.id }).then((res) => {
          this.tags = allTags
          const result = res.data.entries[0]
          this.selectedTags = result.tags.map((item) => item.name) || []
          console.log(res.data.entries[0].tags.map((item) => item.name), '我是已经选中的')
          this.current_corporation_id = record.id
        })
      })
    },
    onCloseDrawer () {
      this.DrawerVisible = false
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          corporation_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              console.log('删除成功------')
          })
          .catch((error) => {
            console.log(error)
            this.confirmLoading = false
          })
         .finally(() => {
              this.confirmLoading = false
         })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {}
    }
  }
}
</script>
