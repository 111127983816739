<template>
  <a-modal
    title="新建企业"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="企业名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="英文名">
              <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="注册资本">
              <a-input v-decorator="['registered_capital', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="法人">
              <a-input v-decorator="['legal_person', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="统一社会信用代码">
              <a-input v-decorator="['credit_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="在园区内的企业状态">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                    initialValue: '1'
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option value="1">
                  待入园
                </a-select-option>
                <a-select-option value="2">
                  已入园
                </a-select-option>
                <a-select-option value="3">
                  已注销
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所在园区">
              <a-select
                v-decorator="[
                  'location',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                    initialValue: '1'
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="是否是规模以上企业">
              <a-switch v-decorator="['is_above_size', {initialValue: false, valuePropName: 'checked'}]" />
            </a-form-item>
            <a-form-item label="法人手机">
              <a-input v-decorator="['legal_person_cellphone', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="进出口企业代码">
              <a-input v-decorator="['import_export_code', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="企业注册地址" >
              <a-input v-decorator="['domicile', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注">
              <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
// 表单字段
const fields = ['id', 'name', 'en_name', 'registered_capital', 'domicile', 'credit_code',
  'import_export_code', 'legal_person', 'legal_person_cellphone', 'status', 'location', 'is_above_size', 'remark', 'tags']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 3 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
